


























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ProductVariant } from "@/store/products-module";
import { required, requiredIf } from "@/common/validators/rules";

@Component({ components: {} })
export default class ProductsForm extends Vue {
    validators = { required, requiredIf };
    @Prop() variant!: ProductVariant;

    @Emit("delete")
    remove() {
        return this.variant;
    }
}















































import { Component, Vue, Emit } from "vue-property-decorator";
import { OpponentsApi, Opponent } from "@/store/opponents-api";
import { VenuesApi, Venue } from "@/store/venues-api";
import CForm from "@/common/components/CForm.vue";
import { required, requiredIf } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class OpponentsForm extends Vue {
    opponentsStore = new OpponentsApi();
    venuesStore = new VenuesApi();
    validators = { required, requiredIf };
    dialog = false;
    loading = false;
    item: Opponent = {} as Opponent;
    venues: Venue[] = [];

    get formTitle() {
        return !this.item.id ? "New Opponent" : "Edit Opponent";
    }

    open(item: Opponent) {
        return this.venuesStore
            .getAll(false)
            .then((res) => (this.venues = res.data))
            .then(() => (this.item = Object.assign({}, item)))
            .then(() => (this.dialog = true))
            .then(() => (this.$refs.form as any).resetValidation());
    }

    close() {
        this.dialog = false;
    }

    @Emit("updated")
    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.loading = true;
        return this.opponentsStore
            .save({ opponent: this.item })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}





























import { Component, Vue, Emit } from "vue-property-decorator";
import { VolunteersApi, Volunteer } from "@/store/volunteers-api";
import CForm from "@/common/components/CForm.vue";
import CDatePicker from "@/common/components/CDatePicker.vue";

@Component({ components: { CForm, CDatePicker } })
export default class VolunteerDialog extends Vue {
    volunteersStore = new VolunteersApi();
    dialog = false;
    loading = false;
    volunteer: Volunteer = {} as Volunteer;

    open(volunteer: Volunteer) {
        this.volunteer = volunteer;
        this.dialog = true;
    }

    close() {
        this.dialog = false;
    }

    @Emit("updated")
    save() {
        this.loading = true;
        return this.volunteersStore
            .edit({ volunteer: this.volunteer })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}





































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProductsModule, Product } from "@/store/products-module";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import ProductsForm from "./ProductsForm.vue";

@Component({ components: { ProductsForm, CDeleteConfirm } })
export default class Products extends Vue {
    productsStore = getModule(ProductsModule);
    products: Product[] = [];
    headers = [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Display?", value: "display" },
        { text: "Actions", value: "actions", sortable: false },
    ];
    search = "";
    loading = false;

    mounted() {
        this.get();
    }

    get() {
        this.loading = true;
        return this.productsStore
            .getAll()
            .then((res) => (this.products = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: Product) {
        (this.$refs.upsert as ProductsForm).open(item || ({} as Product));
    }

    confirmDelete(item: Product) {
        (this.$refs.delete as CDeleteConfirm).open(item);
    }

    deleteConfirmed(item: Product) {
        this.loading = true;
        this.productsStore
            .delete({ productId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}

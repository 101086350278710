
































import { Component, Vue, Emit, Ref } from "vue-property-decorator";
import { CampsApi, Camp } from "@/store/camps-api";
import CForm from "@/common/components/CForm.vue";
import { required } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class CampsForm extends Vue {
    @Ref() readonly form!: CForm;

    api = new CampsApi();
    validators = { required };
    dialog = false;
    loading = false;
    item: Camp = {} as Camp;

    open(item: Camp) {
        this.item = Object.assign({ enabled: true }, item);
        this.dialog = true;
    }

    close() {
        this.dialog = false;
    }

    @Emit("updated")
    save() {
        if (!this.form.validate()) {
            return;
        }

        this.loading = true;
        return this.api
            .save({ camp: this.item })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}

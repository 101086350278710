


































import { Component, Vue } from "vue-property-decorator";
import { VenuesApi, Venue } from "@/store/venues-api";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import VenuesForm from "./VenuesForm.vue";

@Component({ components: { VenuesForm, CDeleteConfirm } })
export default class Venues extends Vue {
    venueStore = new VenuesApi();
    venues: Venue[] = [];
    headers = [
        { text: "Name", value: "name" },
        { text: "Address", value: "formatted_address" },
        { text: "Is Home?", value: "isHome" },
        { text: "Fixtures", value: "fixtures" },
        { text: "Opponents", value: "opponents" },
        { text: "Actions", value: "actions", sortable: false },
    ];
    search = "";
    loading = false;

    mounted() {
        this.get();
    }

    get() {
        this.loading = true;
        return this.venueStore
            .getAll()
            .then((res) => (this.venues = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: Venue) {
        (this.$refs.upsert as VenuesForm).open(item || ({} as Venue));
    }

    confirmDelete(item: Venue) {
        (this.$refs.delete as CDeleteConfirm).open(item);
    }

    deleteConfirmed(item: Venue) {
        this.loading = true;
        this.venueStore
            .delete({ venueId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}

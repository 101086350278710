






































import { Component, Vue } from "vue-property-decorator";
import { VolunteersApi, Volunteer } from "@/store/volunteers-api";
import formatDate from "@/common/filters/format-date";
import VolunteerDialog from "./VolunteerDialog.vue";

@Component({ components: { VolunteerDialog }, filters: { formatDate } })
export default class Subs extends Vue {
    volunteersStore = new VolunteersApi();
    headers = [
        { text: "Season", value: "season" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Registration Date", value: "registrationDate" },
        { text: "Safe Guarding Date", value: "safeGuardingDate" },
        { text: "Garda Vetting Date", value: "gardaVettingDate" },
    ];
    search = "";
    loading = false;
    volunteers: Volunteer[] = [];

    mounted() {
        this.get();
    }

    get() {
        return this.volunteersStore
            .getAll()
            .then((res) => (this.volunteers = res.data))
            .then(() => (this.loading = false));
    }

    get downloadUrl() {
        return "/api/volunteers/download";
    }

    edit(item: Volunteer) {
        (this.$refs.dialog as VolunteerDialog).open(item);
    }
}




















































import { Component, Vue } from "vue-property-decorator";
import { UsersApi, User } from "@/store/users-api";
import UsersForm from "./UsersForm.vue";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";

@Component({ components: { CDeleteConfirm, UsersForm } })
export default class Users extends Vue {
    userStore = new UsersApi();
    users: User[] = [];
    headers = [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "OCBC Email", value: "ocbcEmail" },
        { text: "Official?", value: "isOfficial" },
        { text: "Has Photo?", value: "imageUri" },
        { text: "Admin?", value: "isAdmin" },
        { text: "Actions", value: "actions", sortable: false },
    ];
    search = "";
    loading = false;

    mounted() {
        this.get();
    }

    get() {
        this.loading = true;
        return this.userStore
            .getAll()
            .then((res) => (this.users = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: User) {
        (this.$refs.upsert as any).open(item);
    }

    confirmDelete(item: User) {
        (this.$refs.delete as any).open(item);
    }

    deleteConfirmed(item: User) {
        this.loading = true;
        this.userStore
            .deleteUser(item)
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }

    fullSearch(value: any, search: string, item: User) {
        if (!search) return true;

        let lSearch = search?.toLowerCase();
        return (
            value != null &&
            lSearch != null &&
            typeof value === "string" &&
            (value
                .toString()
                .toLowerCase()
                .indexOf(lSearch) !== -1 ||
                `${item.firstName} ${item.lastName}`.toLowerCase().indexOf(lSearch) !== -1)
        );
    }
}

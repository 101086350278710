import { axios } from "./axios";
import FileUtils from "@/common/utils/fileUtils";

export class OpponentsApi {
    getAll() {
        return axios.get<Opponent[]>("/opponents");
    }

    save({ opponent }: { opponent: Opponent }) {
        const formData = FileUtils.objectToFormData(opponent);
        if (!!opponent.id && opponent.id > -1) {
            return axios.put("/opponents", formData);
        } else {
            return axios.post("/opponents", formData);
        }
    }

    delete({ opponentId }: { opponentId: number }) {
        return axios.delete(`/opponents/${opponentId}`);
    }
}

export interface Opponent {
    id: number;
    name: string;
    imageUpload: Blob;
    venues: string[];
    venueIds: number[];
    mediaUri: string;
}

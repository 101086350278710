









































import { Component, Vue } from "vue-property-decorator";
import { OpponentsApi, Opponent } from "@/store/opponents-api";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import OpponentsForm from "./OpponentsForm.vue";

@Component({ components: { CDeleteConfirm, OpponentsForm } })
export default class Opponents extends Vue {
    opponentStore = new OpponentsApi();
    opponents: Opponent[] = [];
    headers = [
        { text: "Name", value: "name" },
        { text: "Venues", value: "venues" },
        { text: "Image", value: "mediaUri" },
        { text: "Actions", value: "actions", sortable: false },
    ];
    search = "";
    loading = false;

    mounted() {
        this.get();
    }

    get() {
        this.loading = false;

        return this.opponentStore
            .getAll()
            .then((res) => (this.opponents = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: Opponent) {
        (this.$refs.upsert as OpponentsForm).open(item);
    }

    confirmDelete(item: Opponent) {
        (this.$refs.delete as CDeleteConfirm).open(item);
    }

    deleteConfirmed(item: Opponent) {
        this.loading = true;
        this.opponentStore
            .delete({ opponentId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}

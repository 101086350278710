


























































import { Component, Vue, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProductsModule, Product } from "@/store/products-module";
import { required, requiredIf, maxLength } from "@/common/validators/rules";
import CForm from "@/common/components/CForm.vue";
import ProductsVariant from "./ProductsVariant.vue";

@Component({ components: { CForm, ProductsVariant } })
export default class ProductsForm extends Vue {
    productsStore = getModule(ProductsModule);
    validators = { required, requiredIf, maxLength };
    dialog = false;
    loading = false;
    item: Product = {} as Product;

    get formTitle() {
        return !this.item.id ? "New Product" : "Edit Product";
    }

    open(item: Product) {
        this.item = Object.assign({ formatted_address: "", lat: 0, lng: 0, url: "" }, item);
        this.dialog = true;
        this.$nextTick(() => (this.$refs.form as any).resetValidation());
    }

    close() {
        this.dialog = false;
    }

    @Emit("updated")
    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.loading = true;
        return this.productsStore
            .save({ product: this.item })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}

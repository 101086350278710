
































import { Component, Vue, Ref } from "vue-property-decorator";
import { CompetitionsApi, Competition } from "@/store/competitions-api";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import CompetitionsForm from "./CompetitionsForm.vue";

@Component({ components: { CDeleteConfirm, CompetitionsForm } })
export default class Competitions extends Vue {
    @Ref() readonly upsertForm!: CompetitionsForm;
    @Ref() readonly deleteForm!: CDeleteConfirm;

    api = new CompetitionsApi();
    competitions: Competition[] = [];
    headers = [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
    ];
    search = "";
    loading = false;

    mounted() {
        this.get();
    }

    get() {
        this.loading = false;

        return this.api
            .getAll()
            .then((res) => (this.competitions = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: Competition) {
        this.upsertForm.open(item);
    }

    confirmDelete(item: Competition) {
        this.deleteForm.open(item);
    }

    deleteConfirmed(item: Competition) {
        this.loading = true;
        this.api
            .delete({ competitionId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}



















import { Component, Vue } from "vue-property-decorator";
import { AdminApi } from "@/store/admin-api";

@Component({ components: {} })
export default class AdminTasks extends Vue {
    adminApi = new AdminApi();
    loading = false;
    success = false;
    error = "";

    newSeason() {
        this.loading = true;
        return this.adminApi
            .newSeason()
            .then(() => (this.loading = false))
            .then(() => (this.success = true))
            .catch((ex) => (this.error = ex.response.data));
    }
}
















































import { Component, Vue, Emit } from "vue-property-decorator";
import { UsersApi, User } from "@/store/users-api";
import CForm from "@/common/components/CForm.vue";
import { required, maxLength, email } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class UsersForm extends Vue {
    userStore = new UsersApi();
    validators = { required, maxLength, email };
    dialog = false;
    loading = false;
    item: User = {} as User;

    get formTitle() {
        return !this.item.id ? "New User" : "Edit User";
    }

    open(item: User) {
        this.item = Object.assign({}, item);
        this.dialog = true;
    }

    close() {
        this.dialog = false;
    }

    removeImage() {
        this.item.imageUri = "";
    }

    @Emit("updated")
    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.loading = true;
        return this.userStore
            .upsert(this.item)
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}


































import { Component, Vue, Ref } from "vue-property-decorator";
import { CampsApi, Camp } from "@/store/camps-api";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import CampsForm from "./CampsForm.vue";

@Component({ components: { CDeleteConfirm, CampsForm } })
export default class Camps extends Vue {
    @Ref() readonly upsertForm!: CampsForm;
    @Ref() readonly deleteForm!: CDeleteConfirm;

    api = new CampsApi();
    camps: Camp[] = [];
    headers = [
        { text: "Name", value: "name" },
        { text: "Member Price", value: "memberPrice" },
        { text: "Non Member Price", value: "nonMemberPrice" },
        { text: "Enabled", value: "enabled" },
        { text: "Actions", value: "actions", sortable: false },
    ];
    search = "";
    loading = false;

    mounted() {
        this.get();
    }

    get() {
        this.loading = false;

        return this.api
            .getAll()
            .then((res) => (this.camps = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: Camp) {
        this.upsertForm.open(item);
    }

    confirmDelete(item: Camp) {
        this.deleteForm.open(item);
    }

    deleteConfirmed(item: Camp) {
        this.loading = true;
        this.api
            .delete({ campId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}

import { axios } from "./axios";

import FileUtils from "@/common/utils/fileUtils";

export class UsersApi {
    getAll() {
        return axios.get<User[]>("/users");
    }

    upsert(user: User) {
        const formData = FileUtils.objectToFormData(user);
        return axios.post("/users", formData);
    }

    deleteUser(user: User) {
        return axios.delete(`/users/${user.id}`);
    }
}

export interface User {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    imageUri: string;
    image: Blob;
    ocbcEmail: string;
    isOfficial: boolean;
    isAdmin: boolean;
    isApprovedMember: boolean;
}

import { axios } from "./axios";

export class CompetitionsApi {
    getAll() {
        return axios.get<Competition[]>(`/competitions`);
    }

    get({ competitionId }: { competitionId: number }) {
        if (!competitionId) {
            return Promise.resolve({ data: {} as Competition });
        }
        return axios.get<Competition>(`/competitions/${competitionId}`);
    }

    save({ competition }: { competition: Competition }) {
        if (!!competition.id && competition.id > -1) {
            return axios.put("/competitions", competition);
        } else {
            return axios.post("/competitions", competition);
        }
    }

    delete({ competitionId }: { competitionId: number }) {
        return axios.delete(`/competitions/${competitionId}`);
    }
}

export interface Competition {
    id: number;
    name: string;
}

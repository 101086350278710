var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"custom-filter":_vm.fullSearch,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Users")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('users-form',{ref:"upsert",on:{"updated":function($event){return _vm.get()}}}),_c('c-delete-confirm',{ref:"delete",attrs:{"title":function (item) { return item.firstName + ' ' + item.lastName; }},on:{"confirmed":_vm.deleteConfirmed}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.isOfficial",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isOfficial),expression:"item.isOfficial"}],attrs:{"color":"primary"}},[_vm._v("mdi-check")])]}},{key:"item.imageUri",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!!item.imageUri),expression:"!!item.imageUri"}],attrs:{"color":"primary"}},[_vm._v("mdi-check")])]}},{key:"item.isAdmin",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isAdmin),expression:"item.isAdmin"}],attrs:{"color":"primary"}},[_vm._v("mdi-check")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.upsertItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.confirmDelete(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }














import { Component, Vue, Emit, Ref } from "vue-property-decorator";
import { CompetitionsApi, Competition } from "@/store/competitions-api";
import CForm from "@/common/components/CForm.vue";
import { required } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class CompetitionsForm extends Vue {
    @Ref() readonly form!: CForm;

    api = new CompetitionsApi();
    validators = { required };
    dialog = false;
    loading = false;
    item: Competition = {} as Competition;

    open(item: Competition) {
        this.item = Object.assign({}, item);
        this.dialog = true;
        // return this.api.getAll(false)
        //     .then(res => this.venues = res.data)
        //     .then(() => this.item = Object.assign({}, item))
        //     .then(() => this.dialog = true)
        //     .then(() => (this.$refs.form as any).resetValidation())
    }

    close() {
        this.dialog = false;
    }

    @Emit("updated")
    save() {
        if (!this.form.validate()) {
            return;
        }

        this.loading = true;
        return this.api
            .save({ competition: this.item })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}











































import { Component, Vue, Emit } from "vue-property-decorator";
import { VenuesApi, Venue } from "@/store/venues-api";
import CForm from "@/common/components/CForm.vue";
import { required, requiredIf } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class VenuesForm extends Vue {
    venuesStore = new VenuesApi();
    validators = { required, requiredIf };
    dialog = false;
    loading = false;
    item: Venue = {} as Venue;
    place = "";

    get formTitle() {
        return !this.item.id ? "New Venue" : "Edit Venue";
    }

    open(item: Venue) {
        this.place = "";
        this.item = Object.assign({ formatted_address: "", lat: 0, lng: 0, url: "" }, item);
        this.dialog = true;
        this.$nextTick(() => (this.$refs.form as any).resetValidation());
    }

    close() {
        this.dialog = false;
    }

    placeChanged(data: any) {
        this.item.lat = data.geometry.location.lat();
        this.item.lng = data.geometry.location.lng();
        this.item.formatted_address = data.formatted_address;
        this.item.url = data.url;
    }

    @Emit("updated")
    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.loading = true;
        return this.venuesStore
            .save({ venue: this.item })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}
